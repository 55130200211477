.deprecation-banner {
    $min-width: 1020px;
    background-color: #FFFFFF;
    margin: 2vh calc((max(100vw, #{$min-width}) - #{$min-width})/2);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    min-width: #{$min-width};
    max-height: 215px;
}

.image-container {
    margin: 2vh;
}

.close-button-container {
    align-self: flex-start;
    margin-left: auto;
}

.close-button-container Button {
    border: none !important;
    font-size: 2em !important;
    color: black !important;
}

p,
span {
    font-size: large;
}